<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Quantité en stock pour chaque distributeur">
          <stacked-column-chart
            :data="chartData"
            category-field="product"
            :series="chartSeries"
            x-axis-title="Produits"
            y-axis-title="Quantité en stock"
          />
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Moyenne des ventes et niveau de stock actuel pour chaque produit">
          <stacked-column-chart
            :data="data1"
            category-field="product"
            :series="series1"
            x-axis-title="Produit"
            y-axis-title="Quantité"
          />
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Quantité en stock pour chaque distributeur">
          <vue-good-table-vue
            :columns="columns"
            :rows="rows"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'

export default {
  name: 'MainVue',
  components: {
    VueGoodTableVue,
    StackedColumnChart,
    BCard,
    BRow,
    BCol,

  },
  data() {
    return {
      chartData: [
        {
          product: 'Produit A', distributeur1: 100, distributeur2: 120, distributeur3: 80,
        },
        {
          product: 'Produit B', distributeur1: 80, distributeur2: 60, distributeur3: 90,
        },
        {
          product: 'Produit C', distributeur1: 50, distributeur2: 70, distributeur3: 100,
        },
        {
          product: 'Produit D', distributeur1: 120, distributeur2: 80, distributeur3: 60,
        },
      ],
      chartSeries: [
        { seriesName: 'Distributeur 1', valueField: 'distributeur1', color: '#FF9E0F' },
        { seriesName: 'Distributeur 2', valueField: 'distributeur2', color: '#FFC43D' },
        { seriesName: 'Distributeur 3', valueField: 'distributeur3', color: '#FFE5B4' },
      ],

      columns: [
        {
          label: 'Produit',
          field: 'produit',
          filterable: true,
        },
        {
          label: 'Point de vente',
          field: 'point_vente',
          filterable: true,
        },
        {
          label: 'Distributeur',
          field: 'distributeur',
          filterable: true,
        },
        {
          label: 'Sous-dépôt',
          field: 'sous_depot',
          filterable: true,
        },
        {
          label: 'Type',
          field: 'type',
          filterable: true,
        },
        {
          label: 'Quantité',
          field: 'quantite',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Date',
          field: 'date',
          filterable: true,
        },
      ],
      rows: [
        {
          produit: 'Produit 1',
          point_vente: 'Point de vente 1',
          distributeur: 'Distributeur 1',
          sous_depot: 'Sous-dépôt 1',
          type: 'Entrée',
          quantite: 100,
          date: '01/01/2022',
        },
        {
          produit: 'Produit 1',
          point_vente: 'Point de vente 2',
          distributeur: 'Distributeur 1',
          sous_depot: 'Sous-dépôt 1',
          type: 'Sortie',
          quantite: 20,
          date: '02/01/2022',
        },
        {
          produit: 'Produit 2',
          point_vente: 'Point de vente 1',
          distributeur: 'Distributeur 2',
          sous_depot: 'Sous-dépôt 2',
          type: 'Entrée',
          quantite: 50,
          date: '03/01/2022',
        },
        {
          produit: 'Produit 2',
          point_vente: 'Point de vente 2',
          distributeur: 'Distributeur 2',
          sous_depot: 'Sous-dépôt 2',
          type: 'Entrée',
          quantite: 60,
          date: '04/01/2022',
        },
        {
          produit: 'Produit 2',
          point_vente: 'Point de vente 1',
          distributeur: 'Distributeur 2',
          sous_depot: 'Sous-dépôt 2',
          type: 'Sortie',
          quantite: 10,
          date: '05/01/2022',
        },
      ],

      data1: [
        {
          product: 'Produit A',
          stock: 300,
          sales: 25,
        },
        {
          product: 'Produit B',
          stock: 150,
          sales: 10,
        },
        {
          product: 'Produit C',
          stock: 450,
          sales: 30,
        },
        {
          product: 'Produit D',
          stock: 200,
          sales: 20,
        },
        {
          product: 'Produit E',
          stock: 100,
          sales: 5,
        },
      ],
      series1: [
        {
          seriesName: 'Stock',
          valueField: 'stock',
        },
        {
          seriesName: 'Ventes moyennes',
          valueField: 'sales',
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
Ce tableau permet de visualiser l'historique des entrées et sorties de chaque produit dans chaque point





}
  },
}
</script>

<style scoped>

</style>
